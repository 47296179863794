import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { unparseListingType } from '../../util/misc';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  CustomSpinner,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import { sendEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionClientTestimonials from './sectionClientTestimonials';
import css from './ListingPage.module.css';
import BPWTLogo from '../../assets/icons/BPWTLogo.png';
import ListingPagePlus from './ListingPagePlus';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionWorktrippLoves from './SectionWorktrippLoves';
import SectionRates from './SectionRates';
import SectionThingsToDo from './SectionThingsToDo';
import SectionFacilities from './SectionFacilities';

import fileIcon from '../../assets/icons/fileIcon.png';
import { editWorktripp } from '../WorktrippPage/Worktripp.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, true);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.ratesRef = React.createRef();
    this.overviewRef = React.createRef();
    this.videoRef = React.createRef();
    this.facilitiesRef = React.createRef();
    this.specialismRef = React.createRef();
    this.accreditationRef = React.createRef();
    this.testimonialsRef = React.createRef();

    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      worktripp: null,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const listingType = listing.attributes.publicData.listingType;

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();
    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title), listingType },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onToggleEnquiryModal = () => {
    this.setState({ enquiryModalOpen: !this.state.enquiryModalOpen });
  };

  setSelectedWorktripp = worktripp => {
    this.setState({ worktripp });
  };

  addListingToWorktripp = async (tx_id, listing_id) => {
    try {
      const { onEditWorktripp } = this.props;
      const worktripp = await onEditWorktripp({
        _id: this.state.worktripp._id,
        listings: this.state.worktripp.listings.map(ls => {
          if (ls.listing_id == listing_id) {
            ls.tx_id = tx_id;
          }
          return ls;
        }),
      });
      return worktripp;
    } catch (err) {
      console.log(err);
    }
  };

  onSubmitEnquiry(values) {
    this.setState({ loading: true, enquiryModalOpen: false });
    const { history, params, onSendEnquiry, currentUser } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message, wtRange, infoToShare } = values;
    const dates = wtRange
      ? wtRange
      : {
          startDate: new Date(this.state.worktripp?.startDate),
          endDate: new Date(this.state.worktripp?.endDate),
        };

    onSendEnquiry(
      listingId,
      message.trim(),
      dates,
      this.state.worktripp,
      infoToShare,
      currentUser.id.uuid
    )
      .then(txId => {
        this.addListingToWorktripp(txId.uuid, listingId.uuid)
          .then(() => {
            // Redirect to OrderDetailsPage
            history.push(
              createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
            );
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const listingTypeSlug = currentListing.attributes?.publicData?.listingType;
    const params = { slug: listingSlug, listingType: listingTypeSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'policy';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData = [],
    } = currentListing.attributes;

    const {
      listingType: listingTypeId,
      serviceType = '',
      clientTestimonials = [],
      introductoryVideo = '',
      extraFiles = [],
    } = publicData;

    const renderListItems = (array, filterName) => {
      const options = findOptionsForSelectFilter(filterName, config.custom.filters);
      const labels = array
        .map((item, i) => {
          const label = options?.find(option => option.key == item)?.label;
          if (!label) return null;
          if (i == array.length - 1) {
            return label;
          }
          return `${label}, `;
        })
        .filter(v => v);
      return Array.from(new Set(labels));
    };

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <div className={css.bookingPanelWtTitle}>
        <img src={BPWTLogo} />
        <FormattedMessage id="ListingPage.BookingPanelWtTilte" />
      </div>
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    // const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const showContactUser = false;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const allLargeImages = listingImages(currentListing, 'landscape-crop');
    const upTo3Images = allLargeImages.slice(1, 4);

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const alsoIncludes = findOptionsForSelectFilter('others', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const facilitiesOptions = findOptionsForSelectFilter('facilities', filterConfig);

    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {this.state.loading ? <CustomSpinner /> : null}
            <div className={css.thumbSection}>
              <div className={css.imgGrid}>
                <SectionImages
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
                <div className={css.rightImgGellery}>
                  {upTo3Images.map(img => (
                    <img
                      src={img.url}
                      className={css.ImgGelleryThumb}
                      onClick={handleViewPhotosClick}
                    />
                  ))}
                </div>
              </div>
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <div className={serviceType == 'CFS' ? css.serviceTypeCFS : css.serviceTypeVE}>
                    {unparseListingType(listingTypeId)}
                  </div>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                  <span ref={this.overviewRef}></span>

                  <ListingPagePlus
                    currentListing={currentListing}
                    mapRef={this.mapRef}
                    overviewRef={this.overviewRef}
                    ratesRef={this.ratesRef}
                    videoRef={this.videoRef}
                    facilitiesRef={this.facilitiesRef}
                    renderListItems={renderListItems}
                    accreditationRef={this.accreditationRef}
                    testimonialsRef={this.testimonialsRef}
                  />

                  <span ref={this.accreditationRef}></span>
                  <SectionDescriptionMaybe description={description} />
                  <SectionWorktrippLoves currentListing={currentListing} />
                  <span ref={this.testimonialsRef}></span>

                  <SectionDetailsMaybe currentListing={currentListing} />

                  {clientTestimonials.length ? (
                    <div>
                      <SectionClientTestimonials
                        tesimonials={clientTestimonials}
                        images={currentListing.images}
                      />
                    </div>
                  ) : null}

                  {serviceType == 'VE' &&
                  publicData.alsoIncludes &&
                  publicData.alsoIncludes.length > 0 ? (
                    <SectionFeaturesMaybe options={alsoIncludes} publicData={publicData} />
                  ) : null}

                  {publicData.facilities && publicData.facilities.length ? (
                    <>
                      <span ref={this.facilitiesRef}></span>
                      <hr className={css.seperator}></hr>
                    </>
                  ) : null}

                  {serviceType == 'VE' && (
                    <SectionFacilities
                      facilitiesOptions={facilitiesOptions}
                      facilities={publicData.facilities}
                    />
                  )}

                  <hr className={css.seperator} />

                  <SectionRulesMaybe publicData={publicData} />

                  {extraFiles && extraFiles.length > 0 ? (
                    <>
                      <h2 className={css.subTitle}>
                        <FormattedMessage id="ListingPage.extraFilesTitle" />
                      </h2>
                      {extraFiles.map(f => {
                        return (
                          <div key={f.fileKey} className={css.uploadedExtraFiles}>
                            <img src={fileIcon} />
                            <a href={f.fileURL} target="_blank">
                              {f.fileName}
                            </a>
                          </div>
                        );
                      })}
                      <hr className={css.seperator} />
                    </>
                  ) : null}

                  <span ref={this.mapRef}></span>

                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    currentListing={currentListing}
                  />

                  <SectionThingsToDo currentListing={currentListing} />

                  {serviceType == 'VE' && <hr className={css.seperator}></hr>}

                  <span ref={this.ratesRef}></span>

                  {serviceType == 'VE' && <SectionRates currentListing={currentListing} />}

                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    EnquiryInitialValues={{
                      wtRange: {
                        startDate: new Date(this.state.worktripp?.startDate),
                        endDate: new Date(this.state.worktripp?.endDate),
                      },
                      infoToShare: ['companyName', 'workTrippDescription', 'noOfParticipants'],
                    }}
                  />

                  <span ref={this.videoRef}></span>
                  {introductoryVideo && (
                    <iframe
                      className={css.iframe}
                      title="YouTube video player"
                      type="text/html"
                      src={introductoryVideo}
                      frameborder="0"
                      allowFullScreen
                    />
                  )}

                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                </div>

                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  worktrippView={true}
                  onToggleEnquiryModal={this.onToggleEnquiryModal}
                  setSelectedWorktripp={this.setSelectedWorktripp}
                  selectedWorktripp={this.state.worktripp}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, dates, worktripp, infoToShare, userId) =>
    dispatch(sendEnquiry(listingId, message, dates, worktripp, infoToShare, userId)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onEditWorktripp: values => dispatch(editWorktripp(values)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
